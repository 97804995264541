import React, { ReactElement } from 'react'
import 'bootstrap/scss/bootstrap.scss'
import '../src/styles/main.scss'
import 'antd/dist/antd.css'
import Routes from '../src/layouts/routes/routes'

const App = (): ReactElement => {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  )
}

export default App
