import { LOGIN_LOADING, LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";

const initialState = {
  name: "",
  email: "",
  role: "",
  exp: null,
  error: false,
  success: false,
  loading: false,
};

const authRedcer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      state.loading = true;
      return state;
    }
    case LOGIN_SUCCESS: {
      state = { ...state, ...action.payload };
      state.loading = false;
      return state;
    }
    case LOGIN_LOADING: {
      return state;
    }
    default: {
      state.loading = false;
      return state;
    }
  }
};

export default authRedcer;
