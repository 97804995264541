import decode from "jwt-decode";
import PropTypes from "prop-types";
import { ReactElement } from "react";
import { Route, useHistory } from "react-router-dom";
import Login from "../../components/auth/index";

export const PrivateRoute = ({
  component: Component,
  exact,
  path,
  ...rest
}: any): ReactElement => {
  const history = useHistory();
  if (
    localStorage.getItem("ntcl_token") &&
    (decode(localStorage.getItem("ntcl_token")) as any)?.exp * 1000 >
      Date.now() / 1000
  ) {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.hasOwnProperty("ntcl_token") ? (
            <Component {...props} />
          ) : (
            <Login />
          )
        }
      />
    );
  } else {
    localStorage.removeItem("ntcl_token");
    history.push("/");
  }
  return <></>;
};
PrivateRoute.propTypes = {
  component: PropTypes.any,
  exact: PropTypes.bool,
  path: PropTypes.any,
};
export default PrivateRoute;
