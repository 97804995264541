import LogoD from '../images/d.png'

export const wait = (ms: number) => {
  const date = Date.now()
  let currentDate = null
  do {
    currentDate = Date.now()
  } while (currentDate - date < ms)
}

export const validUrl = (url: string) => /^(http|www\.)/gi.test(url)

export const getImgUrl = (url: string) =>
  url ? (validUrl(url) ? url : `${process.env.REACT_APP_ASSETS}${url}`) : LogoD

export const redirect = (url: string) => {
  if (validUrl(url)) {
    window.location.href = url
  } else {
    if (url) {
      if (url?.[0] === '/') {
        window.location.href = `${url}`
      } else {
        window.location.href = `/${url}`
      }
    } else {
      window.location.href = '/'
    }
  }
}

export const getPage = (query: string) => {
  const page = new URLSearchParams(query || window.location.search)
  return parseInt(page.get('page') as string)
}

export const getParam = (query: string) => {
  const page = new URLSearchParams(window.location.search)
  return page.get(query)
}
export const getQueryParams = () =>
  window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    )

export const getUpdatedUrl = (key?: string, value?: any) => {
  const obj = window.location.search
    .replace('?', '')
    .split('&')
    .reduce(
      (r, e) => ((r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1])), r),
      {}
    )
  console.log('ne obj', obj)

  const newObj = []
  for (const [key2, value2] of Object.entries(obj)) {
    if (key2 && value2 && key2 !== key) {
      newObj.push({
        [key2]: value2,
      })
    }
  }

  if (key && value) {
    newObj.push({ [key]: value })
  }

  let str = ''
  for (const [key, value] of Object.entries(newObj)) {
    str +=
      (Object?.keys(value)?.[0] as string) +
      '=' +
      (Object.values(value)?.[0] as string) +
      '&'
  }
  const newUrl = `${window.location.pathname}?${str.slice(0, str?.length - 1)}`

  return newUrl
}

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
