import { Button, Col, Drawer, Form, Input, Row, Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
const Add = ({ userInfo, visibleData, onCloseMethod, refetchProjects }) => {
  const [fileListEquipment, setFileListEquipment] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);
  console.log(userInfo);
  // loading
  const [loading, setLoading] = useState(false);
  // error message
  const [getError, setError] = useState("");
  const [getStatus, setStatus] = useState<string>("");
  const [getType, setType] = useState<string>("");

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  /*===================================
  submit form for add project
  ====================================*/
  const onFinish = async (value) => {
    if (true) {
      setLoading(true);
      const file = new FormData();
      file.append("image", fileList?.[0]?.originFileObj);
      file.append("project_name", value?.project_name);
      file.append("procuring_entity", value?.procuring_entity);
      file.append("vendor", value?.vendor);
      file.append("project_location", value?.project_location);
      file.append("project_length", value?.project_length);
      file.append("project_value", value?.project_value);
      file.append("project_category", value?.project_category);
      file.append("project_type", value?.project_type);
      file.append("status", value?.status);

      await fetch(`${process.env.REACT_APP_PROJECT__POST_API}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Created Successfully", "success");
            onCloseMethod();
            setLoading(false);
            refetchProjects();
            setFileList([]);
          } else {
            setLoading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  };
  return (
    <Drawer
      title="Create A New Project"
      width={700}
      destroyOnClose={true}
      onClose={() => {
        onCloseMethod();
        setFileList([]);
      }}
      visible={visibleData}
      bodyStyle={{ paddingBottom: 0 }}
      footer={
        <div
          style={{
            textAlign: "left",
          }}
        ></div>
      }
    >
      <div className="drawer-toggle-wrapper">
        <div className="drawer-toggle-inner-wrapper">
          <Form className="ant-form ant-form-vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Project Name"
                  name="project_name"
                  rules={[
                    {
                      required: true,
                      message: "name is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Project Name"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Project Location"
                  name="project_location"
                  rules={[
                    {
                      required: true,
                      message: "Location is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Project Location"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Procuring Entity Details"
                  name="procuring_entity"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="Procuring Entity Details"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Vendor"
                  name="vendor"
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Vendor"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Project Length"
                  name="project_length"
                  rules={[
                    {
                      required: true,
                      message: "length is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Project Length"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Project Value"
                  name="project_value"
                  rules={[
                    {
                      required: true,
                      message: "value is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Project Value"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Project Description"
                  name="project_category"
                  rules={[
                    {
                      required: true,
                      message: "Project Description is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Project Description"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label="Please Select Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Status is required",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Status"
                    onChange={(value) => {
                      setStatus(value as string);
                    }}
                    optionFilterProp="children"
                  >
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={0}>Inactiev</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Please Select Project Type"
                  name="project_type"
                  rules={[
                    {
                      required: true,
                      message: "project type  is required",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Project Type"
                    onChange={(value) => {
                      setType(value as string);
                    }}
                    optionFilterProp="children"
                  >
                    <Select.Option value="ongoing">ongoing</Select.Option>
                    <Select.Option value="completed">completed</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="image">
                  <ImgCrop rotate aspect={2 / 1}>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList?.length < 1 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                {getError ? (
                  <p
                    style={{
                      color: "red",
                      border: "1px solid red",
                      padding: "10px",
                    }}
                  >
                    {getError}
                  </p>
                ) : undefined}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    disabled={loading}
                    loading={(loading ? "loading" : undefined) as any}
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};
Add.propTypes = {
  visibleData: PropTypes.bool,
  onCloseMethod: PropTypes.func,
  userInfo: PropTypes.object,
  refetchProjects: PropTypes.func,
};
const mapStateTopProps = (state: { authReducer: { email: string } }) => {
  return {
    userInfo: state.authReducer,
  };
};
export default connect(mapStateTopProps, null)(Add);
