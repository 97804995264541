import { Button, Col, Form, Input, Row, Select, Skeleton, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
const Edit = ({ singleNewsId, onCloseMethod, refetchNews }) => {
  const [getError, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [getSkelton, setSkelton] = useState(true);
  const [getSingleInfo, setSingleInfo] = useState(undefined);
  const [getStatus, setStatus] = useState<string>("");
  const [getDisabled, setDisabled] = useState(true);
  const editor = useRef();
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setDisabled(false);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const fetchSingleNews = useCallback(() => {
    if (singleNewsId !== undefined) {
      try {
        fetch(`${process.env.REACT_APP_NEWS_API}/${singleNewsId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setSingleInfo(data?.data);
            setSkelton(false);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [singleNewsId]);
  useEffect(() => {
    fetchSingleNews();
  }, [fetchSingleNews, singleNewsId]);

  useEffect(() => {
    setFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: `${getSingleInfo?.[0]?.featured_image}`,
      },
    ]);
  }, [getSingleInfo]);

  const onFinish = async (value) => {
    const file = new FormData();
    file.append("featured_image", fileList?.[0]?.originFileObj);
    file.append("news_title", value?.news_title);
    file.append("status", value?.status);
    file.append("news_description", value?.news_description);

    if (true) {
      setLoading(true);
      await fetch(`${process.env.REACT_APP_NEWS__PUT_API}/${singleNewsId}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Updated Successfully", "success");
            setLoading(false);
            onCloseMethod();
            refetchNews();
          } else {
            setLoading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          console.error(err);
          // responseNotification(`Internal server error ${err}`, "error");
        });
    }
  };
  return (
    <div>
      {getSkelton ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <div className="drawer-toggle-wrapper">
          <div className="drawer-toggle-inner-wrapper">
            <Form className="ant-form ant-form-vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="News Title"
                    name="news_title"
                    initialValue={getSingleInfo?.[0].news_title}
                    rules={[
                      {
                        required: true,
                        message: "title is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter News Title"
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="News Description"
                      name="news_description"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "News description is required",
                      //   },
                      // ]}
                    >
                      <SunEditor
                        defaultValue={getSingleInfo?.[0]?.news_description}
                        getSunEditorInstance={getSunEditorInstance}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Please Select Status"
                    name="status"
                    initialValue={getSingleInfo?.[0]?.status}
                    rules={[
                      {
                        required: true,
                        message: "Status is required",
                      },
                    ]}
                  >
                    <Select
                      showSearch={true}
                      placeholder="Select Role"
                      onChange={(value) => {
                        setStatus(value as string);
                      }}
                      optionFilterProp="children"
                    >
                      <Select.Option value={1}>Active</Select.Option>
                      <Select.Option value={0}>Inactiev</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="image">
                    <ImgCrop rotate aspect={1 / 1}>
                      <Upload
                        action="http://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  {getError ? (
                    <p
                      style={{
                        color: "red",
                        border: "1px solid red",
                        padding: "10px",
                      }}
                    >
                      {getError}
                    </p>
                  ) : undefined}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      loading={(loading ? "loading" : undefined) as any}
                      type="primary"
                      htmlType="submit"
                      className="text-center"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

Edit.propTypes = {
  singleNewsId: PropTypes.string,
  onCloseMethod: PropTypes.func,
  refetchNews: PropTypes.func,
};
export default Edit;
