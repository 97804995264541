import { EyeInvisibleOutlined } from "@ant-design/icons";
import { ReactElement, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { login } from "../../redux/auth/authAction";
import Loader from "../common/Loader";

const LoginForm = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, setValue, errors } = useForm();
  const dispatch = useDispatch();

  // password show and hide
  const toggleShowPassword = () => {
    const password = document.querySelector("#password");
    const type =
      password.getAttribute("type") === "password" ? "text" : "password";
    password.setAttribute("type", type);
    password.classList.toggle("anticon-eye-invisible");
  };

  // login submit
  const loginSubmit = async (data) => {
    const userData = data?.email;
    const passwordData = data?.password;
    setLoading(true);
    await login(dispatch, {
      email: userData,
      password: passwordData,
    });
    setLoading(false);
  };
  useEffect(() => {
    register({ name: "email" }, { required: true });
    register({ name: "password" }, { required: true });
  }, [register]);

  return loading ? (
    <Loader />
  ) : (
    <div className="admin-login-form">
      <form onSubmit={handleSubmit(loginSubmit)}>
        <div className="single-form-field">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6667 17.5V15.8333C16.6667 14.9493 16.3155 14.1014 15.6904 13.4763C15.0652 12.8512 14.2174 12.5 13.3333 12.5H6.66668C5.78262 12.5 4.93478 12.8512 4.30965 13.4763C3.68453 14.1014 3.33334 14.9493 3.33334 15.8333V17.5"
              stroke="#737373"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.99999 9.16667C11.8409 9.16667 13.3333 7.67428 13.3333 5.83333C13.3333 3.99238 11.8409 2.5 9.99999 2.5C8.15904 2.5 6.66666 3.99238 6.66666 5.83333C6.66666 7.67428 8.15904 9.16667 9.99999 9.16667Z"
              stroke="#737373"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            autoComplete="off"
            ref={register({ required: true })}
            type="text"
            placeholder="Enter Email"
            name="email"
          />
        </div>
        <div className="login-error text-left">
          {errors.email && <p style={{ color: "red" }}>email is required</p>}
        </div>
        <div className="single-form-field">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8333 9.16666H4.16667C3.24619 9.16666 2.5 9.91286 2.5 10.8333V16.6667C2.5 17.5871 3.24619 18.3333 4.16667 18.3333H15.8333C16.7538 18.3333 17.5 17.5871 17.5 16.6667V10.8333C17.5 9.91286 16.7538 9.16666 15.8333 9.16666Z"
              stroke="#737373"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.83335 9.16667V5.83333C5.83231 4.80004 6.21525 3.80322 6.90782 3.03639C7.6004 2.26955 8.5532 1.78742 9.58127 1.68357C10.6093 1.57973 11.6393 1.86158 12.4712 2.47443C13.3032 3.08727 13.8777 3.98737 14.0833 5"
              stroke="#737373"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <input
            autoComplete="off"
            type="password"
            placeholder="Password"
            name="password"
            id="password"
            ref={register({ required: true })}
          />
          <EyeInvisibleOutlined id="ddd" onClick={toggleShowPassword} />
        </div>
        <div className="login-error text-left">
          {errors.password && (
            <p style={{ color: "red" }}>Password is required</p>
          )}
        </div>
        <div className="get-started">
          <input type="submit" className="submit-form" value="Login" />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
