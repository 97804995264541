import { PlusSquareOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { ReactElement, useState } from "react";
import AddDrawer from "./add";
const AddBtn = (props, { fetchCounter }): ReactElement => {
  const [visible, setvisible] = useState<boolean>(false);
  const showDrawer = () => {
    setvisible(true);
  };
  const onClose = () => {
    setvisible(false);
  };
  return (
    <div className="search-box-shadow add-search-box-shadow">
      <Button className="add-btn" onClick={showDrawer}>
        <PlusSquareOutlined />
        Create Counter
      </Button>
      <AddDrawer
        visibleData={visible}
        fetchCounter={fetchCounter}
        onCloseMethod={onClose}
        showDrawerMethod={showDrawer}
        {...props}
      />
    </div>
  );
};
export default AddBtn;
