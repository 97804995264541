import axios from "axios";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { connect } from "react-redux";
import Layout from "../../layouts/index";
const Dashboard = () => {
  const [value, onChange] = useState(new Date());

  /*==================================
  total equipments count
  ===================================*/
  const [getEquipments, setEquipments] = useState(null);
  const fetchEquipments = async () => {
    setEquipments(null);
    const res = await axios.get(`${process.env.REACT_APP_EQUIPMENT_GET_API}`);
    setEquipments(res?.data?.count);
  };
  useEffect(() => {
    fetchEquipments();
  }, []);

  /*==================================
  total news count
  ===================================*/
  const [getNews, setNews] = useState(null);
  const fetchNews = async () => {
    setNews(null);
    const res = await axios.get(`${process.env.REACT_APP_NEWS_API}`);
    setNews(res?.data?.count);
  };
  useEffect(() => {
    fetchNews();
  }, []);

  /*==================================
  total completed projects count
  ===================================*/
  const [getCompletedProjects, setCompletedProjects] = useState([]);
  const fetchCompletedProjects = async () => {
    setCompletedProjects(null);
    const res = await axios.get(`${process.env.REACT_APP_PROJECT_API}`);
    setCompletedProjects(
      res?.data?.data?.filter(
        (project) => project?.project_type === "completed"
      )
    );
  };
  useEffect(() => {
    fetchCompletedProjects();
  }, []);

  /*==================================
  total completed projects count
  ===================================*/
  const [getongoingProjects, setongoingProjects] = useState([]);
  const fetchongoingProjects = async () => {
    setongoingProjects(null);
    const res = await axios.get(`${process.env.REACT_APP_PROJECT_API}`);
    setongoingProjects(
      res?.data?.data?.filter((project) => project?.project_type === "ongoing")
    );
  };
  useEffect(() => {
    fetchongoingProjects();
  }, []);

  return (
    <Layout>
      <div className="dashboard-box-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="dashboard-box amount-box">
                <h2 className="box-heading amout-box-heading">{getNews}</h2>
                <p>Total News</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="dashboard-box pending-order-box ">
                <h2 className="box-heading pending-box-heading">
                  {getCompletedProjects?.length}
                </h2>
                <p>Completed Projects</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="dashboard-box active-shop-box">
                <h2 className="box-heading active-shop-heading">
                  {getongoingProjects?.length}
                </h2>
                <p>Ongoing Projects</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="dashboard-box cancel-order-box">
                <h2 className="box-heading cancel-order-heading">
                  {getEquipments}
                </h2>
                <p>Total Equipment</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="calender-area">
                <Calendar onChange={onChange} value={value} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
Dashboard.propTypes = {
  userInfo: PropTypes.object,
};
const mapStateTopProps = (state: { authReducer: { role: any } }) => {
  return { userInfo: state.authReducer };
};
export default connect(mapStateTopProps, null)(Dashboard);
