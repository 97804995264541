import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { Route } from "react-router-dom";
import Dashboard from "../../pages/dashboard/Index";

const PublicRoute = ({
  component: Component,
  exact,
  path,
  restricted,
  ...rest
}: any): ReactElement => (
  <Route
    {...rest}
    render={(props) => {
      if (localStorage.hasOwnProperty("ntcl_token")) {
        return <Dashboard />;
      } else {
        return <Component {...props} />;
      }
    }}
  />
);
PublicRoute.propTypes = {
  component: PropTypes.object,
  restricted: PropTypes.bool,
  exact: PropTypes.bool,
  path: PropTypes.any,
};
export default PublicRoute;
