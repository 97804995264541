import React, { ReactElement } from "react";
import List from "../../components/counter/list";
import Layout from "../../layouts/index";
const News = (): ReactElement => {
  return (
    <Layout>
      <React.Fragment>
        <List />
      </React.Fragment>
    </Layout>
  );
};

export default News;
