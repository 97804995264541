import { Button, Col, Drawer, Form, Input, Row, Select, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
const Add = ({ userInfo, visibleData, onCloseMethod, refetchNews }) => {
  console.log(userInfo);
  // loading
  const [loading, setLoading] = useState(false);
  // error message
  const [getError, setError] = useState("");
  const [getStatus, setStatus] = useState<string>("");

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  /*===================================
  submit form for add news
  ====================================*/
  const onFinish = async (value) => {
    if (true) {
      setLoading(true);
      const file = new FormData();
      file.append("featured_image", fileList?.[0]?.originFileObj);
      file.append("news_title", value?.news_title);
      file.append("news_description", value?.news_description);
      file.append("status", value?.status);

      await fetch(`${process.env.REACT_APP_NEWS__POST_API}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Created Successfully", "success");
            onCloseMethod();
            setLoading(false);
            refetchNews();
            setFileList([]);
          } else {
            setLoading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          console.error(`Internal server error ${err}`, "error");
          setLoading(false);
        });
    }
  };
  return (
    <Drawer
      title="Create A News"
      width={700}
      destroyOnClose={true}
      onClose={() => {
        setFileList([]);
        onCloseMethod();
      }}
      visible={visibleData}
      bodyStyle={{ paddingBottom: 0 }}
      footer={
        <div
          style={{
            textAlign: "left",
          }}
        ></div>
      }
    >
      <div className="drawer-toggle-wrapper">
        <div className="drawer-toggle-inner-wrapper">
          <Form className="ant-form ant-form-vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="News Title"
                  name="news_title"
                  rules={[
                    {
                      required: true,
                      message: "title is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter News Title"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="News Description"
                  name="news_description"
                  rules={[
                    {
                      required: true,
                      message: "News description is required",
                    },
                  ]}
                >
                  <SunEditor />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Please Select Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Status is required",
                    },
                  ]}
                >
                  <Select
                    showSearch={true}
                    placeholder="Select Status"
                    onChange={(value) => {
                      setStatus(value as string);
                    }}
                    optionFilterProp="children"
                  >
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={0}>Inactiev</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="image">
                    <ImgCrop rotate aspect={1 / 1}>
                      <Upload
                        action="http://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                      >
                        {fileList?.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                {getError ? (
                  <p
                    style={{
                      color: "red",
                      border: "1px solid red",
                      padding: "10px",
                    }}
                  >
                    {getError}
                  </p>
                ) : undefined}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    disabled={loading}
                    loading={(loading ? "loading" : undefined) as any}
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};
Add.propTypes = {
  visibleData: PropTypes.bool,
  onCloseMethod: PropTypes.func,
  userInfo: PropTypes.object,
  refetchNews: PropTypes.func,
};
const mapStateTopProps = (state: { authReducer: { email: string } }) => {
  return {
    userInfo: state.authReducer,
  };
};
export default connect(mapStateTopProps, null)(Add);
