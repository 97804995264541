// backend axios call\
import decode from "jwt-decode";
import { responseNotification } from "../../utils/notify";
import { LOGIN_REQUEST, LOGIN_SUCCESS } from "./authType";

//  login action here
export const login = async (dispatch, { email, password }) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const res = await fetch(`${process.env.REACT_APP_AUTH_API}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    const response = await res.json();
    const { token } = response;

    if (token) {
      localStorage.setItem("ntcl_token", token);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: decode(token),
      });
      responseNotification(
        response?.message?.replace("Operation", "Login"),
        "success"
      );
      window.location.href = `${process.env.PUBLIC_URL}`;
      return true;
    } else {
      responseNotification(response?.message, "error");
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
