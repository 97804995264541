import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Drawer, Popconfirm, Select } from "antd";
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import Loading from "../common/Loader";
import AddBtn from "./addBtn";
import Edit from "./edit";
import Heading from "./heading";
const { Option } = Select;
const List = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [singleNewsId, setSingleNewsId] = useState(undefined);

  /*======================================
 fetch news api call
  =======================================*/
  // get all news state
  const [getNews, setNews] = useState({
    loading: false,
    data: null,
  });
  const fetchNews = async () => {
    setNews({ loading: true, data: null });
    const res = await axios.get(`${process.env.REACT_APP_NEWS_API}`);
    setNews({ loading: false, data: res?.data });
  };
  useEffect(() => {
    fetchNews();
  }, []);

  /*==============================
  delete news
  ===============================*/
  const confirm = async (id) => {
    if (true) {
      setLoading(true);
      await fetch(`${process.env.REACT_APP_NEWS__DELETE_API}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // name,
          // logo,
          // description,
          // deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("News deleted successfully", "success");
            setLoading(false);
            fetchNews();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          responseNotification(`Internal server error ${err}`, "error");
        });
    }
  };

  /*======================================
  add close drawer function
  =======================================*/
  const closeDrawer = () => {
    setSingleNewsId(undefined);
  };

  return (
    <React.Fragment>
      <div className="page-heading-area mb-15">
        <div className="container-fluid no-padding">
          <div className="row">
            <div className="col-lg-7">
              <Heading />
            </div>
            <div className="col-lg-5 text-right">
              <AddBtn refetchNews={fetchNews} />
            </div>
          </div>
        </div>
      </div>
      <div className="list-view-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="list-view-inner-content">
                {getNews?.loading ? (
                  <Loading />
                ) : (
                  <div className="row">
                    {getNews?.data?.data?.length ? (
                      getNews?.data?.data?.map((news, index) => (
                        <div className="col-lg-3" key={index}>
                          <div className="list-view-wrapper">
                            <div className="single-list-view">
                              <div className="logo">
                                <img
                                  src={news?.featured_image}
                                  alt="nationtech news"
                                />
                              </div>
                              <div className="info">
                                <h3 className="name">{news?.news_title}</h3>
                              </div>
                            </div>
                            <div className="list-action text-center">
                              <div className="right-action">
                                <Popconfirm
                                  title={`Are You Sure, You Want To Delete This Equipment?`}
                                  onConfirm={() => {
                                    confirm(news?.id);
                                  }}
                                  // onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a href="#">
                                    <DeleteOutlined />
                                  </a>
                                </Popconfirm>
                                <button
                                  onClick={() => {
                                    setSingleNewsId(news?.id);
                                  }}
                                >
                                  <EditOutlined />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "40px 0",
                        }}
                      >
                        News Not Found
                      </p>
                    )}
                  </div>
                )}

                <Drawer
                  destroyOnClose={true}
                  title="Update News"
                  width={700}
                  onClose={() => setSingleNewsId(undefined)}
                  visible={!!singleNewsId}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    ></div>
                  }
                >
                  <Edit
                    singleNewsId={singleNewsId}
                    refetchNews={fetchNews}
                    onCloseMethod={closeDrawer}
                  />
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default List;
