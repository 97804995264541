import { Button, Col, Drawer, Form, Input, Row, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";

const Add = ({ userInfo, visibleData, onCloseMethod, fetchCounter }) => {
  console.log(userInfo);
  // loading
  const [loading, setLoading] = useState(false);
  // error message
  const [getError, setError] = useState("");
  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  /*===================================
  submit form for add counter
  ====================================*/
  const onFinish = async (value) => {
    if (true) {
      setLoading(true);
      const file = new FormData();
      file.append("icon_image", fileList?.[0]?.originFileObj);
      file.append("title", value?.title);
      file.append("count", value?.count);
      await fetch(`${process.env.REACT_APP_COUNTER_POST_API}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Created Successfully", "success");
            onCloseMethod();
            setLoading(false);
            fetchCounter();
          } else {
            setLoading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };
  return (
    <Drawer
      title="Create A New Counter"
      width={700}
      destroyOnClose={true}
      onClose={onCloseMethod}
      visible={visibleData}
      bodyStyle={{ paddingBottom: 0 }}
      footer={
        <div
          style={{
            textAlign: "left",
          }}
        ></div>
      }
    >
      <div className="drawer-toggle-wrapper">
        <div className="drawer-toggle-inner-wrapper">
          <Form className="ant-form ant-form-vertical" onFinish={onFinish}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  label="Counter Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "title is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Counter Title"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="Count Number"
                  name="count"
                  rules={[
                    {
                      required: true,
                      message: "count number  is required",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Count Number Like... 453"
                    className="ant-input ant-input-lg"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item name="icon_image">
                  <ImgCrop rotate aspect={1 / 1}>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-card"
                      fileList={fileList}
                      onChange={onChange}
                      onPreview={onPreview}
                    >
                      {fileList.length < 1 && "+ Upload"}
                    </Upload>
                  </ImgCrop>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                {getError ? (
                  <p
                    style={{
                      color: "red",
                      border: "1px solid red",
                      padding: "10px",
                    }}
                  >
                    {getError}
                  </p>
                ) : undefined}
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item>
                  <Button
                    disabled={loading}
                    loading={(loading ? "loading" : undefined) as any}
                    type="primary"
                    htmlType="submit"
                    className="text-center"
                  >
                    Save
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </Drawer>
  );
};
Add.propTypes = {
  visibleData: PropTypes.bool,
  onCloseMethod: PropTypes.func,
  userInfo: PropTypes.object,
  fetchCounter: PropTypes.func,
};
const mapStateTopProps = (state: { authReducer: { email: string } }) => {
  return {
    userInfo: state.authReducer,
  };
};
export default connect(mapStateTopProps, null)(Add);
