import { Col, Row, Skeleton } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { authenticateToken } from "../../utils/auth";

const Details = ({ userInfo, singleProjectId }): ReactElement => {
  console.log(userInfo);
  const [getSkelton, setSkelton] = useState(true);
  const [getSingleInfo, setSingleInfo] = useState(undefined);
  const [fileList, setFileList] = useState([]);
  const fetchSingleProject = useCallback(() => {
    if (singleProjectId !== undefined) {
      setSkelton(true);
      try {
        fetch(`${process.env.REACT_APP_PROJECT_API}/${singleProjectId}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setSingleInfo(data?.data);
            setSkelton(false);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [singleProjectId]);
  useEffect(() => {
    fetchSingleProject();
  }, [fetchSingleProject, singleProjectId]);

  return (
    <div>
      {getSkelton ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <Row gutter={16}>
          <Col span={24}>
            <div className="category-details-wrapper">
              <div className="category-details-inner">
                <div className="category-description-wrapper">
                  <div className="category-description-inner">
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Name:</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0]?.project_name}
                        </span>
                      </div>
                    </div>
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Location :</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0].project_location}
                        </span>
                      </div>
                    </div>
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Value :</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0].project_value}
                        </span>
                      </div>
                    </div>
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Length :</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0].project_length}
                        </span>
                      </div>
                    </div>
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Type :</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0].project_type}
                        </span>
                      </div>
                    </div>
                    <div className="single-content">
                      <div className="heading">
                        <span>Project Category :</span>
                      </div>
                      <div className="content">
                        <span className="description">
                          {getSingleInfo?.[0].project_category}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
Details.propTypes = {
  singleProjectId: PropTypes.string,
  userInfo: PropTypes.object,
};
const mapStateTopProps = (state: { authReducer: { email: string } }) => {
  return {
    userInfo: state.authReducer,
  };
};
export default connect(mapStateTopProps, null)(Details);
