import { Button, Col, Form, Input, Row, Skeleton, Upload } from "antd";
import ImgCrop from "antd-img-crop";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
const Edit = ({ singleCounter, onCloseMethod, refetchCounter }) => {
  const [getError, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [getSkelton, setSkelton] = useState(true);
  const [getSingleInfo, setSingleInfo] = useState(undefined);
  const [getDisabled, setDisabled] = useState(true);

  const [fileList, setFileList] = useState([]);
  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    setDisabled(false);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const fetchSingleCounter = useCallback(() => {
    if (singleCounter !== undefined) {
      try {
        fetch(`${process.env.REACT_APP_COUNTER_GET_API}/${singleCounter}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authenticateToken()}`,
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then((data) => {
            setSingleInfo(data?.data);
            setSkelton(false);
            setLoading(false);
          })
          .catch((err) => console.log(err));
      } catch (error) {
        console.log(error, "error");
      }
    }
  }, [singleCounter]);
  useEffect(() => {
    fetchSingleCounter();
  }, [fetchSingleCounter, singleCounter]);

  useEffect(() => {
    setFileList([
      {
        uid: "-1",
        name: "image.png",
        status: "done",
        url: `${getSingleInfo?.[0]?.icon_image}`,
      },
    ]);
  }, [getSingleInfo]);

  const onFinish = async (value) => {
    if (true) {
      setLoading(true);
      const file = new FormData();
      file.append("icon_image", fileList?.[0]?.originFileObj);
      file.append("title", value?.title);
      file.append("count", value?.count);
      await fetch(`${process.env.REACT_APP_COUNTER_PUT_API}/${singleCounter}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
        },
        body: file,
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Updated Successfully", "success");
            setLoading(false);
            onCloseMethod();
            refetchCounter();
          } else {
            setLoading(false);
            setError(res?.message);
          }
        })
        .catch((err) => {
          responseNotification(`Internal server error ${err}`, "error");
        });
    }
  };
  return (
    <div>
      {getSkelton ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <div className="drawer-toggle-wrapper">
          <div className="drawer-toggle-inner-wrapper">
            <Form className="ant-form ant-form-vertical" onFinish={onFinish}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    initialValue={getSingleInfo?.[0]?.title}
                    label="Counter Title"
                    name="title"
                    rules={[
                      {
                        required: true,
                        message: "title is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Counter Title"
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    initialValue={getSingleInfo?.[0]?.count}
                    label="Count Number"
                    name="count"
                    rules={[
                      {
                        required: true,
                        message: "count number is required",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter Count Number"
                      className="ant-input ant-input-lg"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item name="icon_image">
                    <ImgCrop rotate aspect={1 / 1}>
                      <Upload
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={fileList}
                        onChange={onChange}
                        onPreview={onPreview}
                      >
                        {fileList.length < 1 && "+ Upload"}
                      </Upload>
                    </ImgCrop>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  {getError ? (
                    <p
                      style={{
                        color: "red",
                        border: "1px solid red",
                        padding: "10px",
                      }}
                    >
                      {getError}
                    </p>
                  ) : undefined}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item>
                    <Button
                      loading={(loading ? "loading" : undefined) as any}
                      type="primary"
                      htmlType="submit"
                      className="text-center"
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      )}
    </div>
  );
};

Edit.propTypes = {
  singleCounter: PropTypes.object,
  onCloseMethod: PropTypes.func,
  refetchCounter: PropTypes.func,
};
export default Edit;
