import {
  AccountBookOutlined,
  AppstoreOutlined,
  FileDoneOutlined,
  LineChartOutlined,
  TableOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import React, { ReactElement, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { logout } from "../utils/auth";
const { Header, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Content } = Layout;
const Layouts = ({ children }: any): ReactElement => {
  const [getCollapsed, setCollapsed] = useState(false);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={getCollapsed} onCollapse={onCollapse}>
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        <Menu theme="dark" mode="inline">
          <Menu.Item
            key="1"
            icon={<AppstoreOutlined />}
            className={location.pathname == "/dashboard" ? "active" : ""}
          >
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>

          <Menu.Item
            key="2"
            icon={<LineChartOutlined />}
            className={location.pathname == "/projects" ? "active" : ""}
          >
            <Link to="/projects">Projects</Link>
          </Menu.Item>

          <Menu.Item
            key="4"
            icon={<FileDoneOutlined />}
            className={location.pathname == "/equipments" ? "active" : ""}
          >
            <Link to="/equipments">Equipments</Link>
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<TableOutlined />}
            className={location.pathname == "/news" ? "active" : ""}
          >
            <Link to="/news">News</Link>
          </Menu.Item>

          <Menu.Item
            key="11"
            icon={<AccountBookOutlined />}
            className={location.pathname == "/counter" ? "active" : ""}
          >
            <Link to="/counter">Counter</Link>
          </Menu.Item>

          <SubMenu key="sub2" icon={<UserOutlined />} title="Setting">
            <Menu.Item key="6">
              <Link onClick={() => logout("/")} to="/">
                Logout
              </Link>
            </Menu.Item>
          </SubMenu>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ padding: "15px" }} className="main-content-layout">
          {children}
        </Content>
        <Footer style={{ textAlign: "center" }}>
          Nationtech Communication LTD
        </Footer>
      </Layout>
    </Layout>
  );
};
export default Layouts;

Layouts.propTypes = {
  children: PropTypes.element,
};
