import { applyMiddleware, compose, createStore } from "redux";
import { persistStore } from "redux-persist";
import promiseMiddleware from "redux-promise";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";

export const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(promiseMiddleware, thunk)
    //             process.env.NODE_ENV !== 'production' && module.hot ?
    //     (applyMiddleware(promiseMiddleware, thunk),
    //             window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f)
    //   :
    //       applyMiddleware(promiseMiddleware, thunk)
  )
);

// export function configureStore(preloadedState) {
//   const middlewares = [promiseMiddleware, thunk]
//   const middlewareEnhancer = applyMiddleware(...middlewares)

//   const enhancers = [middlewareEnhancer]
//   const composedEnhancers = compose(...enhancers)

//   const store = createStore(rootReducer, preloadedState, composedEnhancers)

//   if (process.env.NODE_ENV !== 'production' && module.hot) {
//     module.hot.accept('./reducers', () => store.replaceReducer(rootReducer))
//   }

//   return store
// }

export const persistor = persistStore(store);
