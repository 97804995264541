import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Drawer, Popconfirm, Select } from "antd";
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { CounterType } from "../../types/counter";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import Loading from "../common/Loader";
import AddBtn from "./addBtn";
import Edit from "./edit";
import Heading from "./heading";
const { Option } = Select;
const List = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [singleCounter, setSingleCounter] = useState(undefined);
  /*======================================
 fetch counter api call
  =======================================*/
  // get all counter state
  const [getCounters, setCounters] = useState({
    loading: false,
    data: null,
  });
  const fetchCounter = async () => {
    setCounters({ loading: true, data: null });
    const res = await axios.get(`${process.env.REACT_APP_COUNTER_GET_API}`);
    setCounters({ loading: false, data: res?.data });
  };
  useEffect(() => {
    fetchCounter();
  }, []);

  /*==============================
  delete counter
  ===============================*/
  const confirm = async (id) => {
    if (true) {
      setLoading(true);
      await fetch(`${process.env.REACT_APP_COUNTER_DELETE_API}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // name,
          // logo,
          // description,
          // deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Counter deleted successfully", "success");
            setLoading(false);
            fetchCounter();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          responseNotification(`Internal server error ${err}`, "error");
        });
    }
  };

  /*======================================
  add close drawer function
  =======================================*/
  const closeDrawer = () => {
    setSingleCounter(undefined);
  };

  return (
    <React.Fragment>
      <div className="page-heading-area mb-15">
        <div className="container-fluid no-padding">
          <div className="row">
            <div className="col-lg-7">
              <Heading />
            </div>
            <div className="col-lg-5 text-right">
              <AddBtn fetchCounter={fetchCounter} />
            </div>
          </div>
        </div>
      </div>
      <div className="list-view-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="list-view-inner-content">
                {getCounters?.loading ? (
                  <Loading />
                ) : (
                  <div className="row">
                    {getCounters?.data?.data?.length ? (
                      getCounters?.data?.data?.map(
                        (counterData: CounterType, index) => (
                          <div className="col-lg-3" key={index}>
                            <div className="list-view-wrapper">
                              <div className="single-list-view">
                                <div className="logo">
                                  <img
                                    src={counterData?.icon_image}
                                    alt="nationtech counter banner"
                                  />
                                </div>
                                <div className="info">
                                  <h3 className="name">{counterData?.title}</h3>
                                  <h6>{counterData?.count}</h6>
                                </div>
                              </div>
                              <div className="list-action text-center">
                                <div className="right-action">
                                  <Popconfirm
                                    title={`Are You Sure, You Want To Delete This Counter?`}
                                    onConfirm={() => {
                                      confirm(counterData?.id);
                                    }}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                  >
                                    <a href="#">
                                      <DeleteOutlined />
                                    </a>
                                  </Popconfirm>
                                  <button
                                    onClick={() => {
                                      setSingleCounter(counterData?.id);
                                    }}
                                  >
                                    <EditOutlined />
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "40px 0",
                        }}
                      >
                        Counter Not Found
                      </p>
                    )}
                  </div>
                )}

                <Drawer
                  destroyOnClose={true}
                  title="Update Counter"
                  width={700}
                  onClose={() => setSingleCounter(undefined)}
                  visible={!!singleCounter}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    ></div>
                  }
                >
                  <Edit
                    singleCounter={singleCounter}
                    refetchCounter={fetchCounter}
                    onCloseMethod={closeDrawer}
                  />
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default List;
