import { ReactElement } from 'react'
import styled from 'styled-components'
import Loader1 from '../../images/load.gif'
const LoaderWrapper = styled.div`
  height: 200px;
  width: 200px;
  margin: auto;
  position:relative;
`
const LoaderContent = styled.div`
  position: absolute;
  height: 50px;
  width: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(230, 45, 38, .2);
  padding: 10px;
`
const LoaderImg = styled.img`
  height: 100%;
  width: 100%;
`
const Loader = (): ReactElement => {
  return (
    <LoaderWrapper className="loader-wrapper">
      <LoaderContent className="loader-content">
        <LoaderImg src={Loader1} className="loader-img" alt="" />
      </LoaderContent>
    </LoaderWrapper>
  )
}

export default Loader
