import PropTypes from "prop-types";
import React, { ReactElement } from "react";
import { connect } from "react-redux";
import LoginBg from "../../images/loginbg.png";
import Logo from "../../images/logo.png";
import Loader from "../common/Loader";
import LoginForm from "./loginForm";

const loginBgStyle = {
  width: "100%",
  backgroundImage: `url(${LoginBg})`,
  // height: "100vh",
  backgroundPosition: "top center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  position: "relative",
  left: "-2px",
};

const Login = ({ data }): ReactElement => {
  return (
    <React.Fragment>
      {data?.loading ? (
        <Loader />
      ) : (
        <div className="login-container">
          <div className="container-fluid no-padding">
            <div className="row no-gutters">
              <div
                className="col-lg-8 col-md-6 login-banner"
                style={loginBgStyle}
                {...data}
              >
                <div className="login-banner-overlay"></div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="admin-login-wrapper pt-5">
                  <div className="logo">
                    <img src={Logo} alt="nationtech logo" />
                  </div>
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
Login.propTypes = {
  data: PropTypes.object,
};
const mapStateTopProps = (state) => {
  return { data: state.authReducer };
};
export default connect(mapStateTopProps, null)(Login);
