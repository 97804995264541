import PropTypes from "prop-types";
import { ReactElement } from "react";
import { connect } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// all routes import here
import Login from "../../components/auth/index";
import Loader from "../../components/common/Loader";
import Counter from "../../pages/counter/index";
import Dashboard from "../../pages/dashboard/Index";
import Equipments from "../../pages/equipments/index";
import News from "../../pages/news/index";
// not found page
import NotFound from "../../pages/notfound/Index";
import Projects from "../../pages/projects/index";
import { PrivateRoute } from "../routes/PrivateRoutes";
import PublicRoute from "../routes/PublicRoutes";

const Routes = ({ userInfo }): ReactElement<any, any> => {
  if (userInfo?.loading) {
    return <Loader />;
  } else {
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute
            exact={true}
            restricted={true}
            path={`${process.env.PUBLIC_URL}/`}
            component={Login}
          />
          <PrivateRoute
            exact={true}
            path={`${process.env.PUBLIC_URL}/dashboard`}
            component={Dashboard}
          />
          <PrivateRoute
            exact={true}
            path={`${process.env.PUBLIC_URL}/equipments`}
            component={Equipments}
          />
          <PrivateRoute
            exact={true}
            path={`${process.env.PUBLIC_URL}/news`}
            component={News}
          />
          <PrivateRoute
            exact={true}
            path={`${process.env.PUBLIC_URL}/projects`}
            component={Projects}
          />

          <PrivateRoute
            exact={true}
            path={`${process.env.PUBLIC_URL}/counter`}
            component={Counter}
          />

          <Route exact path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    );
  }
};
Routes.propTypes = {
  userInfo: PropTypes.object,
};
const mapStateTopProps = (state: { authReducer: { loading: boolean } }) => {
  return {
    userInfo: state.authReducer,
  };
};
export default connect(mapStateTopProps, null)(Routes);
