import {
  DeleteOutlined,
  EditOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { Drawer, Popconfirm, Select } from "antd";
import axios from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { authenticateToken } from "../../utils/auth";
import { responseNotification } from "../../utils/notify";
import Loading from "../common/Loader";
import AddBtn from "./addBtn";
import Details from "./Details";
import Edit from "./edit";
import Heading from "./heading";
const { Option } = Select;
const List = (): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [singleProjectId, setSingleProjectId] = useState(undefined);
  const [singleProjectDetailsId, setSingleProjectDetailsId] =
    useState(undefined);

  /*======================================
 fetch project api call
  =======================================*/
  // get all project state
  const [getProjects, setProjects] = useState({
    loading: false,
    data: null,
  });
  const fetchProjects = async () => {
    setProjects({ loading: true, data: null });
    const res = await axios.get(`${process.env.REACT_APP_PROJECT_API}`);
    setProjects({ loading: false, data: res?.data });
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  /*==============================
  delete project
  ===============================*/
  const confirm = async (id) => {
    if (true) {
      setLoading(true);
      await fetch(`${process.env.REACT_APP_PROJECT__DELETE_API}/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authenticateToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          // name,
          // logo,
          // description,
          // deleted: true,
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.status === "success") {
            responseNotification("Project deleted successfully", "success");
            setLoading(false);
            fetchProjects();
          } else {
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  /*======================================
  add close drawer function
  =======================================*/
  const closeDrawer = () => {
    setSingleProjectId(undefined);
    setSingleProjectDetailsId(undefined);
  };

  return (
    <React.Fragment>
      <div className="page-heading-area mb-15">
        <div className="container-fluid no-padding">
          <div className="row">
            <div className="col-lg-7">
              <Heading />
            </div>
            <div className="col-lg-5 text-right">
              <AddBtn refetchProjects={fetchProjects} />
            </div>
          </div>
        </div>
      </div>
      <div className="list-view-area">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="list-view-inner-content">
                {getProjects?.loading ? (
                  <Loading />
                ) : (
                  <div className="row">
                    {getProjects?.data?.data?.length ? (
                      getProjects?.data?.data?.map((project, index) => (
                        <div className="col-lg-3" key={index}>
                          <div className="list-view-wrapper">
                            <div className="single-list-view">
                              <div className="logo">
                                <img
                                  src={project?.image}
                                  alt="nationtech project"
                                />
                              </div>
                              <div className="info">
                                <h3 className="name">
                                  {project?.project_name}
                                </h3>
                              </div>
                            </div>
                            <div className="list-action text-center">
                              <div className="right-action">
                                <Popconfirm
                                  title={`Are You Sure, You Want To Delete This Project?`}
                                  onConfirm={() => {
                                    confirm(project?.id);
                                  }}
                                  // onCancel={cancel}
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <a href="#">
                                    <DeleteOutlined />
                                  </a>
                                </Popconfirm>
                                <button
                                  onClick={() => {
                                    setSingleProjectId(project?.id);
                                  }}
                                >
                                  <EditOutlined />
                                </button>

                                <button
                                  onClick={() => {
                                    setSingleProjectDetailsId(project?.id);
                                  }}
                                >
                                  <FolderViewOutlined />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p
                        style={{
                          textAlign: "center",
                          width: "100%",
                          padding: "40px 0",
                        }}
                      >
                        Projects Not Found
                      </p>
                    )}
                  </div>
                )}

                <Drawer
                  destroyOnClose={true}
                  title="Project Update"
                  width={700}
                  onClose={() => setSingleProjectId(undefined)}
                  visible={!!singleProjectId}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    ></div>
                  }
                >
                  <Edit
                    singleProjectId={singleProjectId}
                    refetchProjects={fetchProjects}
                    onCloseMethod={closeDrawer}
                  />
                </Drawer>

                <Drawer
                  destroyOnClose={true}
                  title="Project Details"
                  width={700}
                  onClose={() => setSingleProjectDetailsId(undefined)}
                  visible={!!singleProjectDetailsId}
                  bodyStyle={{ paddingBottom: 80 }}
                  footer={
                    <div
                      style={{
                        textAlign: "right",
                      }}
                    ></div>
                  }
                >
                  <Details singleProjectId={singleProjectDetailsId} />
                </Drawer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default List;
