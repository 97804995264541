import { ReactElement } from "react";
const Heading = (): ReactElement => {
  return (
    <div className="top-heading-categories top-heading-content">
      <div className="page-heading-content">
        <h2>News List</h2>
      </div>
    </div>
  );
};

export default Heading;
