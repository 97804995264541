import React, { ReactElement } from 'react'
import styled from 'styled-components'
import notFound from '../../images/404.png'
import Layout from '../../layouts/index'

const NotFoundStyle = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const NotFound = (): ReactElement => {
  return (
    <Layout>
      <React.Fragment>
        <NotFoundStyle className="page-not-found">
          <img src={notFound} alt="not found" />
        </NotFoundStyle>
      </React.Fragment>
    </Layout>
  )
}

export default NotFound
